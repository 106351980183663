import { createRouter, createWebHistory } from 'vue-router';
import Login from '../view/Login.vue';

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../view/Dashboard.vue'), // Lazy-loaded
    },
    {
        path: '/items',
        name: 'Items',
        component: () => import('../view/Items.vue'), // Lazy-loaded
    },
    {
        path: "/item/:itemId",
        name: "Item-detail",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "../view/ItemDetail.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/item/add",
        name: "Item-add",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "../view/ItemAdd.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/craftings',
        name: 'Craftings',
        component: () => import('../view/Craftings.vue'), // Lazy-loaded
    },
    {
        path: "/crafting/:craftingId",
        name: "Crafting-detail",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "../view/CraftingDetail.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/crafting/add",
        name: "Crafting-add",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "../view/CraftingAdd.vue"),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/files',
        name: 'Files',
        component: () => import('../view/Files.vue'), // Lazy-loaded
    },
    {
        path: '/file/add',
        name: 'File-add',
        component: () => import('../view/FileAdd.vue'), // Lazy-loaded
    },
    {
        path: '/file/:fileId',
        name: 'File-detail',
        component: () => import('../view/FileDetail.vue'), // Lazy-loaded
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!localStorage.getItem('token')) {
            next({ name: 'Login' });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
