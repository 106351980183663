<script>
  import UserServices from "@/services/UserService";


  export default {
    computed: {
    },
    data: () => ({
      user: {},
      loading: false,
      drawer: true,
      rail: true,
    }),
  async created() {
    this.profile();
  },


  methods: {
    profile() {
      UserServices.currentUser()
        .then(res => {
          this.user = res.data;
        })
        .catch(e => {
          console.error("Not authorized");
          UserServices.logout().then(r => {
            this.$router.push({ name: "Login" }).catch(() => {});
          });
        });
    },
    logout() {
      UserServices.logout()
        .then(res => {
          this.$router.push({ name: "Login" }).catch(() => {});
        })
        .catch(e => {
          console.error("e", e);
        });
    },
  }
  }
</script>

<template>
    <v-navigation-drawer
      v-model="drawer"
      :rail="rail"
      permanent
      @click="rail = false"
    >
      <v-list>
          <v-list-item
            :prepend-avatar="user.avatar"
            :title="user.name"
          >
            <template v-slot:append>
              <v-btn
                variant="text"
                icon="mdi-chevron-left"
                @click.stop="rail = !rail"
              ></v-btn>
            </template>
          </v-list-item>
        <v-divider></v-divider>
        <v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-view-dashboard" title="Nástěnka"  @click="$router.push({path: '/dashboard'})" value="dashboard"></v-list-item>
            <v-list-item prepend-icon="mdi-equal-box" title="Položky"  @click="$router.push({path: '/items'})" value="items"></v-list-item>
            <v-list-item prepend-icon="mdi-hammer-wrench" title="Recepty"  @click="$router.push({path: '/craftings'})" value="craftings"></v-list-item>
            <v-list-item prepend-icon="mdi-file" title="Soubory"  @click="$router.push({path: '/files'})" value="files"></v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list-item prepend-icon="mdi-logout" title="Odhlásit" @click="logout"></v-list-item>
      </v-list>
    </v-navigation-drawer>
</template>

<style scoped>

</style>
